<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="仓库发货单号" prop="swarehouseoutno">
      <me-input clearable v-model="form.swarehouseoutno" />
    </me-label>
    <me-label width="300px" title="发货单编号" prop="sorderno">
      <me-input clearable v-model="form.sorderno" />
    </me-label>
    <me-label width="300px" title="发货进度" prop="ideliverystatus">
      <me-input clearable v-model="form.ideliverystatus" />
    </me-label>
    <me-label width="300px" title="提货单状态" prop="istatus">
      <me-input clearable v-model="form.istatus" />
    </me-label>
    <me-label width="300px" title="是否已审核" prop="bischecked">
      <me-input clearable v-model="form.bischecked" />
    </me-label>
    <me-label width="300px" title="物流公司" prop="slogistics">
      <me-input clearable v-model="form.slogistics" />
    </me-label>
    <me-label width="300px" title="运单（物流单）" prop="swaybillno">
      <me-input clearable v-model="form.swaybillno" />
    </me-label>
    <me-label width="300px" title="发货仓库" prop="swarehouse">
      <me-input clearable v-model="form.swarehouse" />
    </me-label>
    <me-label width="300px" title="卖方会员" prop="ssellermember">
      <me-input clearable v-model="form.ssellermember" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      formDefault: {
        sguid: '',
        swarehouseoutno: '',
        sorderno: '',
        ideliverystatus: '',
        istatus: '',
        bischecked: '',
        slogistics: '',
        swaybillno: '',
        swarehouse: '',
        ssellermember: ''
      },
      form: { ...this.formDefault },
      rules: {
        sguid: [
          { required: true, message: '主键不能为空' }
        ],
        swarehouseoutno: [
          { required: true, message: '仓库发货单号不能为空' }
        ],
        sorderno: [
          { required: true, message: '发货单编号不能为空' }
        ],
        ideliverystatus: [
          { required: true, message: '发货进度不能为空' }
        ],
        istatus: [
          { required: true, message: '提货单状态不能为空' }
        ],
        bischecked: [
          { required: true, message: '是否已审核不能为空' }
        ],
        slogistics: [
          { required: true, message: '物流公司不能为空' }
        ],
        swaybillno: [
          { required: true, message: '运单（物流单）不能为空' }
        ],
        swarehouse: [
          { required: true, message: '发货仓库不能为空' }
        ],
        ssellermember: [
          { required: true, message: '卖方会员不能为空' }
        ]
      }
    }
  },
  methods: {
    ...mapActions([ 'selectDeliveryorderByPrimary', 'insertDeliveryorder' ]),
    async save () {
      await this.$refs.form.validate()
      await this.insertDeliveryorder(this.form)
    },
    async refreshData ({ sguid }) {
      this.form = { ...this.formDefault }
      if (this.$tools.notBlank(sguid)) {
        const data = await this.selectDeliveryorderByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data, sguid: undefined })
      }
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
